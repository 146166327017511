<template>
  <div>
    <v-app-bar app color class="white" height="130px">
      <router-link :to="homeUrl">
        <v-img src="/img/logo.png" max-width="100%" link></v-img>
      </router-link>

      <div>
        <v-select
          class="subtitle--text"
          label="Objekt"
          v-model="building"
          hide-details
          prepend-inner-icon="fas fa-building"
          :items="buildingItems"
          flat
          item-value="id"
          :item-text="(item) => item.street + ' ' + item.housenumber"
          solo-inverted
          @change="changeBuilding(building)"
        >
        </v-select>
      </div>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text class="ml-2">
            <v-icon class="mr-2" color="primary">fas fa-user</v-icon>
            {{ $root.user.name }} &nbsp;
            <v-icon>fas fa-caret-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item href="/logout">
            <v-list-item-title>
              <v-icon>fa fa-door-open</v-icon>&nbsp;Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>
<script>
import { globalStore } from "../../app";
export default {
  props: {
    source: String,
  },

  data: () => ({
    building: "",
    drawer: null,
    buildingItems: [],
  }),
  methods: {
    changeBuilding(objectID) {
      console.log(this.building);
      globalStore.currentBuildingID = objectID;
      this.$root.$emit("changeObjectAndTicketData"); //like this
    },
  },
  computed: {
    homeUrl: function () {
      if (this.$root.user.role == "admin") return "/dashboard";
      else return "/tickets";
    },
  },
  created: function () {
    console.log(this);
    axios.get("/api/loadbuildings").then(
      (response) => {
        console.log(response);
        this.buildingItems = response.data;
        if (this.$route.query.ticketid && this.$route.query.buildingid) {
          this.changeBuilding(this.$route.query.buildingid);
          //  this.building = this.$route.query.buildingid
          // this.building = this.buildingItems.indexOf()
          console.log("1");
          this.building = this.buildingItems.find(
            (item) => item.id === parseInt(this.$route.query.buildingid)
          );
          //console.log(this.building)
        }
      },
      (error) => {}
    );
    /* axios.get("/api/buildings/?uid=" + this.$root.user.id).then(
      (response) => {
        console.log(response);
        this.buildingItems = response.data.buildings;
        this.building = response.data.lastBuilding[0];
        globalStore.currentBuildingID = response.data.lastBuilding[0];
      },
      (error) => {
        this.$root.$refs.snackbar.showmessage(
          "Fehler bei Buildingabfrage: " + error,
          "error"
        );
      }
    ); */
  },
};
</script>