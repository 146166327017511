<template>
  <v-container grid-list-xs>
   <v-row justify="center">
    <v-card width="700px" >
      <v-toolbar dark class="primary"><v-toolbar-title>Import</v-toolbar-title> </v-toolbar>
      <v-card-text>
         <v-card>
        
        <v-card-text>
          <v-row class="justify-center">
          <v-col md="6">
          <v-file-input
            v-model="file"
            color="primary"
            label="Datei auswählen"
            hint="*.csv"
            autofocus
           hide-details
            append-icon="fa-file-csv"
            outlined
            
          ></v-file-input></v-col></v-row>
    
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" class="ml-2" @click="massimport()" :loading="loadbtn">
              <v-icon class="mr-2">fas fa-file-import</v-icon>Importieren
            </v-btn>
        </v-card-actions>
         </v-card>
         <v-card  v-if="importlog.length>0" class="mt-2 primary white--text"><v-card-title>Log <v-spacer></v-spacer> <v-icon class="pr-2 white--text">fas fa-upload</v-icon>{{importlog.length}}</v-card-title>
         <v-card-text>
<v-list>
  <v-list-item v-for="(item, index) in importlog" :key="index">
     <v-chip
                      :class="{
                        success: item.status == 'new',
                        yellow: item.status == 'changed',
                        red: item.status == 'deleted'
                       
                      }"
                      class="ml-2 mr-1 white--text"
                      
                      label
                      >{{ item.status }}</v-chip>{{ item.id }};{{ item.partnumber }};{{ item.partname }};{{ item.quantity }};{{ item.uom }};{{ item.condition }};{{ item.location }};{{ item.stockname }};{{ item.certificate }};{{ item.serialnumber }}
         
  </v-list-item>
</v-list>
        
         </v-card-text>
         
         </v-card>
      </v-card-text>
    </v-card>
   </v-row>

  </v-container>
</template>
<script>
export default {
  data: () => ({
    file: [],
    loadbtn:false,
    importlog:[]
  }),
  methods: {
    massimport() {

              let formData = new FormData();
               this.loadbtn=true
        formData.append("file", this.file);
axios
          .post("/api/massimport", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(
            (response) => {
          console.log(response)
      this.loadbtn=false
  
 this.$root.$refs.snackbar.showmessage(
              "Erfolgreich Importiert.",
              "success"
            );
  this.importlog = response.data
            },
            (error) => {
               this.$root.$refs.snackbar.showmessage(
              "Fehler beim Import.",
              "error"
            );
            }
          );
    },
  },
};
</script>