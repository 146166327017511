<template>
  <v-container grid-list-xs>
    <v-row justify="center">
      <v-card width="700px">
        <v-toolbar dark class="primary"
          ><v-toolbar-title>Export</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-card>
            <v-container>
              <v-row align="center">
                <v-col cols="12" md="6" class="align-center"
                  ><v-select
                    label="Stockname"
                    clearable
                    persistent-hint
                    v-model="filter.stockname"
                    :items="stocknames"
                    outlined
                    append-icon="fas fa-sticky-note"
                  ></v-select
                ></v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    hint="Format: *XXX*"
                    clearable
                    persistent-hint
                    outlined
                    v-model="filter.location"
                    label="Location"
                    append-icon="fas fa-search"
                  ></v-text-field
                ></v-col>
              </v-row>
            </v-container>

            <v-card-actions class="justify-end">
              <v-btn color="primary" @click="csvexport()"
                ><v-icon class="pr-2">fas fa-download</v-icon>Export</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    stocknames: ["tedst", "tsd"],
    date: new Date().toISOString().substr(0, 10),
    filter: { stockname: "", location: "" },
  }),
  activated: function () {
    this.getStocknames();
  },
  methods: {
    getStocknames() {
      axios.get("/api/getstocknames").then(
        (response) => {
          console.log(response.data);
          this.stocknames = response.data;
        },
        (error) => {}
      );
    },
    csvexport() {
      axios.post("/api/export", this.filter).then(
        (response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            "aps_consignment_" + this.date + ".csv"
          );
          document.body.appendChild(fileLink);
          fileLink.click();
          this.$root.$refs.snackbar.showmessage(
            "Erfolgreich Exportiert.",
            "success"
          );
        },
        (error) => {}
      );
    },
  },
};
</script>