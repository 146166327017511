<template>
  <v-container fluid>
    <v-card class="ma-2">
      <v-toolbar dark class="success darken-4">
        <v-toolbar-title>Neue Tickets</v-toolbar-title>
      </v-toolbar>

      <v-expansion-panels focusable>
        <v-row class="pa-3">
          <v-col
            v-for="(openTicket, index) in openTickets"
            :key="index"
            cols="12"
            sm="6"
            md="6"
          >
            <v-card flat>
              <v-expansion-panel :id="openTicket.id" class="grey lighten-4">
                <v-expansion-panel-header
                  class="title"
                  expand-icon="fas fa-caret-down"
                >
                  <div>
                    <v-icon class="pr-2">fas fa-clipboard-list</v-icon> LYNR-{{
                      openTicket.id
                    }}
                    {{ openTicket.subject }} {{ openTicket.object.street }}
                    {{ openTicket.object.housenumber }}
                  </div>
                  <v-spacer></v-spacer>
                  <v-icon
                    medium
                    v-if="openTicket.priority == 'Hoch'"
                    color="error"
                    >fas fa-lightbulb</v-icon
                  >
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card class="mx-auto mt-2 grey lighten-5" flat>
                    <v-list-item three-line>
                      <v-list-item-content>
                        <div class="overline mb-4">
                          <v-icon>fas fa-wrench</v-icon>
                          {{ openTicket.subject }}
                        </div>
                        <v-list-item-title class="headline mb-1"
                          >Anfrage von {{ openTicket.name }} mit Priorität
                          {{ openTicket.priority }}</v-list-item-title
                        >
                        <v-list-item-subtitle class="mt-3">
                          <v-icon small>fas fa-align-left</v-icon>
                          Beschreibung
                        </v-list-item-subtitle>
                        <div class="mt-2">{{ openTicket.info }} <br /></div>
                        <v-row class="mt-2">
                          <v-col cols="12" md>
                            <v-list-item-subtitle class="mb-2"
                              ><br />
                              <v-icon small>fas fa-id-card</v-icon> Ersteller
                            </v-list-item-subtitle>
                            {{ openTicket.name }} <br />
                            {{ openTicket.email }} <br />
                            {{ openTicket.floorinfo }}
                          </v-col>
                          <v-col cols="12" md>
                            <v-list-item-subtitle class="mb-2"
                              ><br />
                              <v-icon small>fas fa-user-circle</v-icon>
                              Hausmeister
                            </v-list-item-subtitle>
                            {{ openTicket.facilitymanager.title }}
                            {{ openTicket.facilitymanager.firstname }}
                            {{ openTicket.facilitymanager.firstname }} <br />
                            {{ openTicket.facilitymanager.email }} <br />
                            {{ openTicket.facilitymanager.phone }}
                          </v-col>

                          <v-col cols="12" class="mt-4" md>
                            <v-list-item-subtitle class="mb-2">
                              <v-icon small>fas fa-calendar-week</v-icon>
                              Datum und Uhrzeit
                            </v-list-item-subtitle>
                            <v-datetime-picker clearText="Abbrechen" okText="Bestätigen" v-model="datetime"  :time-picker-props="timeProps" >
                               <template slot="dateIcon">
                    <v-icon class="pr-2">fas fa-calendar</v-icon>Datum
                  </template>
                  <template slot="timeIcon">
                    <v-icon class="pr-2">fas fa-clock</v-icon>Uhrzeit
                  </template>
                               </v-datetime-picker>
                          </v-col>
                        </v-row>
                        <!--    {{ openTicket.object.street }} {{ openTicket.object.housenumber }}
                        {{ openTicket.object.plz }} {{ openTicket.object.city }} -->
                      </v-list-item-content>
                    </v-list-item>

                    <v-card-actions>
                      <v-row>
                        <v-col cols="12" md>
                          <v-label>Dateien Hochladen</v-label><br />
                          <v-input
                            type="file"
                            id="file"
                            ref="file"
                            v-on:change="handleFileUpload()"
                          />

                          <v-btn v-on:click="submitFile()">Submit</v-btn>
                        </v-col>
                        <v-spacer></v-spacer><v-spacer></v-spacer>
                        <v-col cols="12" md>
                          <v-btn text-right class="primary"
                            ><v-icon class="pr-2">fas fa-paper-plane</v-icon
                            >Auftrag übermitteln</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-card>
          </v-col>
        </v-row>
      </v-expansion-panels>
    </v-card>
    <v-card class="ma-2">
      <v-toolbar dark class="yellow darken-4 mt-10">
        <v-toolbar-title> In Arbeit</v-toolbar-title>
      </v-toolbar>
      <v-expansion-panels focusable>
        <v-row class="pa-3">
          <v-col
            v-for="(wipTicket, index) in wipTickets"
            :key="index"
            cols="12"
            sm="6"
            md="6"
          >
            <v-card flat>
              <v-expansion-panel :id="wipTicket.id">
                <v-expansion-panel-header class="title">{{
                  wipTicket.subject
                }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="mt-2">
                    Treppenhaus nicht geputzt<v-spacer></v-spacer>
                    <v-btn class="primary"
                      ><v-icon class="pr-2">fas fa-calendar-check</v-icon
                      >Buchen</v-btn
                    ></v-row
                  >
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-card>
          </v-col>
        </v-row>
      </v-expansion-panels>
    </v-card>
    <v-card class="ma-2">
      <v-toolbar dark class="grey darken-4 mt-10">
        <v-toolbar-title> Abgeschlossen</v-toolbar-title>
      </v-toolbar>
      <v-expansion-panels focusable>
        <v-row class="pa-3">
          <v-col
            v-for="(doneTicket, index) in doneTickets"
            :key="index"
            cols="12"
            sm="6"
            md="6"
          >
            <v-card flat>
              <v-expansion-panel :id="doneTicket.id">
                <v-expansion-panel-header class="title">{{
                  doneTicket.subject
                }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="mt-2">
                    Treppenhaus nicht geputzt<v-spacer></v-spacer>
                    <v-btn class="primary"
                      ><v-icon class="pr-2">fas fa-calendar-check</v-icon
                      >Buchen</v-btn
                    ></v-row
                  >
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-card>
          </v-col>
        </v-row>
      </v-expansion-panels>
    </v-card>
  </v-container>
</template>
<script>
import { globalStore } from "../../app";
export default {
  data() {
    return {
      datetime: "",
      menu: false,
      openTickets: [],
      wipTickets: [],
      doneTickets: [],
      currentBuilding: globalStore.currentBuilding,
      timeProps: {
        format: '24hr',
        
      }
    };
  },

  methods: {
    changeObjectAndTicketData() {
      axios
        .get("/api/loadticketsforobject", {
          params: {
            id: globalStore.currentBuildingID,
          },
        })
        .then(
          (response) => {
            this.openTickets = response.data.openTickets;
            console.log(this.openTickets);
            this.wipTickets = response.data.wipTickets;
            this.doneTickets = response.data.doneTickets;
          },
          (error) => {}
        );
    },
  },

  activated: function () {},
  mounted: function () {
    console.log(this.$route);

    console.log("ja");
    this.$root.$on("changeObjectAndTicketData", () => {
      this.changeObjectAndTicketData();
    });
  },
};
</script>
