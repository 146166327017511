<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
         <v-row justify="center">
           <v-img src="/img/logo.png" max-width="170px" class="mb-2"></v-img></v-row>
          <v-card class="elevation-12">
           
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title class="justify-center">Login</v-toolbar-title>
            </v-toolbar>
            <v-form ref="form" action="/login" method="POST">
              <v-card-text>
                <input type="hidden" name="_csrf" :value="$root.csfr" />
                <v-text-field
                  label="Login"
                  name="username"
                  v-model="username"
                  prepend-icon="mdi-account"
                  type="text"
                ></v-text-field>

                <v-text-field
                  id="password"
                  label="Password"
                  v-model="password"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                ></v-text-field>
              </v-card-text>
              <v-card-actions class="justify-center">
                
                <v-btn color="primary" type="submit">Login</v-btn>
            
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
export default {
  data: () => ({
    username: "",
    password: "",
  }),
  props: {
    source: String,
  },
  methods: {
    login() {
 
    },

  },

};
</script>