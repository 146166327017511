<template>
  <div>
  
    <v-snackbar
      v-model="snackbar"
	  :color="color"
    :timeout="3000"
    >
      {{ text }}
      <v-btn
        color="white"
        text
        @click="snackbar = false"
      >
        Schließen
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
  export default {
    data: () => ({
	  snackbar: false,
	  color:'',
      text: '',
	}),
	methods:{
		showmessage(text,color){
			this.text=text
			this.color=color
			this.snackbar=true
		}
	}
  }
</script>