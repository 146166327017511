<template>
  <v-container grid-list-xs>
    <v-card>
      <v-toolbar dark class="primary">
        <v-toolbar-title>Buchungen vom:</v-toolbar-title>

        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <div max-width="200px">
              <v-text-field
                v-model="date"
                label="Tag auswählen"
                append-icon="fas fa-calendar-day"
                readonly
                v-bind="attrs"
                solo-inverted
                v-on="on"
                outlined
                class="ml-2"
                hide-details
              ></v-text-field>
            </div>
          </template>

          <v-card>
            <v-date-picker
              :range="multipledays"
              @input="datepickerFunc()"
              locale="de"
              v-model="date"
            ></v-date-picker>
            <v-card-actions class="justify-center">
              <div v-if="multipledays">
                <v-btn @click="getBookings();menu2 = false" class="primary">Confirm</v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-checkbox
          solo-inverted
          label="Zeitraum"
          class="pa-2 pt-7"
          v-model="multipledays"
          @change="resetDays()"
        ></v-checkbox>
        <div max-width="200px">
          <v-select
            solo-inverted
            v-model="selectUser"
            prepend-inner-icon="fa fa-user"
            label="Benutzer"
            item-text="username"
             
            :items="users"
            hide-details
            
            @change="getBookings()"
            outlined
          ></v-select>
        </div>
                  <div max-width="200px">
              <v-text-field
                v-model="filterID"
                label="Filter ID"
                append-icon="fa fa-hashtag"
           
              clearable
                solo-inverted
           @input="getBookings()"
                outlined
                class="ml-2"
                hide-details
              ></v-text-field>
            </div>
       
      </v-toolbar>
      <v-card-text>
        <v-container fluid>
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Date</th>
                  <th class="text-left">Type</th>
                  <th class="text-left">User</th>
                  <th class="text-left">Item Id</th>
                  <th class="text-left">Partnumber</th>
                  <th class="text-left">Partname</th>
                  <th class="text-left">Quantity</th>
                  <th class="text-left">Uom</th>
                  <th class="text-left">Condition</th>
                  <th class="text-left">Location</th>
                  <th class="text-left">Stockname</th>
                  <th class="text-left">Certificate</th>
                  <th class="text-left">Expired</th>
                  <th class="text-left">Serialnumber</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in bookings" :key="index">
                  <td class="pa-1">
                 
                    {{ item.created_at }}
                  </td>
                  <td class="pa-1">
                    <v-chip
                      :class="{
                        success: item.type == 'create',
                        red: item.type == 'change',
                        error: item.type == 'delete',
                      }"
                      class="ml-2 mr-1"
                      dark
                      label
                      >{{ item.type }}</v-chip
                    >
                  </td>
                  <td >
                    {{ item.updated_by }}
                  </td>
                  <td>
                    {{ item.item_id }}
                  </td>
                  <td>
                    <v-tooltip
                      bottom
                      v-if="
                        item.type != 'create' &&
                        item.partnumber != item.partnumber_old
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <a
                          label
                          v-bind="attrs"
                          v-on="on"
                          class="red--text font-weight-bold"
                          >{{ item.partnumber }}</a
                        >
                      </template>
                      <span> {{ item.partnumber_old }}</span>
                    </v-tooltip>
                    <div v-else>{{ item.partnumber }}</div>
                  </td>
                  <td>
                    <v-tooltip
                      bottom
                      v-if="
                        item.type != 'create' &&
                        item.partname != item.partname_old
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <a
                          label
                          v-bind="attrs"
                          v-on="on"
                          class="red--text font-weight-bold"
                          >{{ item.partname }}</a
                        >
                      </template>
                      <span> {{ item.partname_old }}</span>
                    </v-tooltip>
                    <div v-else>{{ item.partname }}</div>
                  </td>
                  <td>
                    <v-tooltip
                      bottom
                      v-if="
                        item.type != 'create' &&
                        item.quantity != item.quantity_old
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <a
                          label
                          v-bind="attrs"
                          v-on="on"
                          class="red--text font-weight-bold"
                          >{{ item.quantity }}</a
                        >
                      </template>
                      <span> {{ item.quantity_old }}</span>
                    </v-tooltip>
                    <div v-else>{{ item.quantity }}</div>
                  </td>
                  <td>
                    <v-tooltip
                      bottom
                      v-if="item.type != 'create' && item.uom != item.uom_old"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <a
                          label
                          v-bind="attrs"
                          v-on="on"
                          class="red--text font-weight-bold"
                          >{{ item.uom }}</a
                        >
                      </template>
                      <span> {{ item.uom_old }}</span>
                    </v-tooltip>
                    <div v-else>{{ item.uom }}</div>
                  </td>
                  <td>
                    <v-tooltip
                      bottom
                      v-if="
                        item.type != 'create' &&
                        item.condition != item.condition_old
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <a
                          label
                          v-bind="attrs"
                          v-on="on"
                          class="red--text font-weight-bold"
                          >{{ item.condition }}</a
                        >
                      </template>
                      <span> {{ item.condition_old }}</span>
                    </v-tooltip>
                    <div v-else>{{ item.condition }}</div>
                  </td>
                  <td>
                    <v-tooltip
                      bottom
                      v-if="
                        item.type != 'create' &&
                        item.location != item.location_old
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <a
                          label
                          v-bind="attrs"
                          v-on="on"
                          class="red--text font-weight-bold"
                          >{{ item.location }}</a
                        >
                      </template>
                      <span> {{ item.location_old }}</span>
                    </v-tooltip>
                    <div v-else>{{ item.location }}</div>
                  </td>
                  <td>
                    <v-tooltip
                      bottom
                      v-if="
                        item.type != 'create' &&
                        item.stockname != item.stockname_old
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <a
                          label
                          v-bind="attrs"
                          v-on="on"
                          class="red--text font-weight-bold"
                          >{{ item.stockname }}</a
                        >
                      </template>
                      <span> {{ item.stockname_old }}</span>
                    </v-tooltip>
                    <div v-else>{{ item.stockname }}</div>
                  </td>
                  <td>
                    <v-tooltip
                      bottom
                      v-if="
                        item.type != 'create' &&
                        item.certificate != item.certificate_old
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <a
                          label
                          v-bind="attrs"
                          v-on="on"
                          class="red--text font-weight-bold"
                          >{{ item.certificate }}</a
                        >
                      </template>
                      <span> {{ item.certificate_old }}</span>
                    </v-tooltip>
                    <div v-else>{{ item.certificate }}</div>
                  </td>
                  <td>
                    <v-tooltip
                      bottom
                      v-if="
                        item.type != 'create' &&
                        item.expired != item.expired_old
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <a
                          label
                          v-bind="attrs"
                          v-on="on"
                          class="red--text font-weight-bold"
                          >{{ item.expired }}</a
                        >
                      </template>
                      <span> {{ item.expired_old }}</span>
                    </v-tooltip>
                    <div v-else>{{ item.expired }}</div>
                  </td>
                  <td>
                    <v-tooltip
                      bottom
                      v-if="
                        item.type != 'create' &&
                        item.serialnumber != item.serialnumber_old
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <a
                          label
                          v-bind="attrs"
                          v-on="on"
                          class="red--text font-weight-bold"
                          >{{ item.serialnumber }}</a
                        >
                      </template>
                      <span> {{ item.serialnumber_old }}</span>
                    </v-tooltip>
                    <div v-else>{{ item.serialnumber }}</div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    dateNew: new Date().toISOString().substr(0, 10),
    multipledays: false,
    page: 1,
   
    users: [],
    selectUser:'Alle',
    bookings: {},
    filterID:'',
    menu2: false,
  }),
  activated: function () {
    axios.get("/api/getuser").then(
      (response) => {
       
        this.users = response.data;
        this.users.unshift('Alle')
      },
      (error) => {}
    );
this.getBookings()
  },
  computed: {},

  methods: {
    getBookings(){
    axios.post("/api/getbookings", {date:this.date,user:this.selectUser,filterID:this.filterID}).then(
      (response) => {
      
        this.bookings = response.data;
      },
      (error) => {}
    );
    },
    exporttocsv() {
      this.$root.$refs.snackbar.showmessage(
        "Erfolgreich Exportiert.",
        "success"
      );
    },
    datepickerFunc() {
      if (!this.multipledays) {
        this.menu2 = false;
          this.getBookings()
      }
    
    },
    resetDays() {
      if (!this.multipledays) {
        this.date = this.dateNew;
         this.getBookings()
      }
    },
  },
};
</script>