<template>
  <v-container>
    <v-row justify="center">
      <v-card width="700px">
        <v-toolbar dark class="primary">
          <v-toolbar-title>
            <v-btn
              icon
              @click="
                edituserbool = false;
                (edituser = Object.assign({})), (editusermodal = true);
              "
              class="mr-2"
            >
              <v-icon color="" large>fas fa-plus-circle</v-icon> </v-btn
            >Benutzerverwaltung</v-toolbar-title
          ></v-toolbar
        >

        <v-card-text>
          <v-card class="mb-2" v-for="user in users" :key="user.id">
            <v-card-title>
              <v-icon class="pr-2" color="primary">fas fa-user</v-icon
              >{{ user.username }}
              <v-chip class="ml-2">{{ user.rolle.rollenname.name }}</v-chip>
              <v-spacer></v-spacer>
              <v-btn
                @click="
                  edituserbool = true;
                  edituser = Object.assign({}, user);
                  edituser.rolle = user.rolle.rollenname.name;
                  editusermodal = true;
                "
                color="primary"
              >
                <v-icon>fas fa-edit</v-icon>
              </v-btn>
            </v-card-title>
          </v-card>
        </v-card-text>

        <v-dialog
          v-model="editusermodal"
          persistent
          :overlay="false"
          max-width="500px"
          transition="dialog-transition"
        >
          <v-card>
            <v-toolbar dark class="primary text-h6">
              <div v-if="edituserbool">
                <v-icon class="pr-2">fas fa-edit</v-icon>Benutzer bearbeiten
                <v-btn dark class="error mt-2" @click="confirm = true">
                  <v-icon>fas fa-trash</v-icon>
                </v-btn>
                <div class="text-subtitle-2">
                  Erstellt am: {{ edituser.created_at }}
                </div>
              </div>
              <div v-else>
                <v-icon class="pr-2">fas fa-plus-square</v-icon>Neuer Benutzer
              </div>
              <v-spacer></v-spacer>
              <v-btn
                class="primary lighten-2"
                icon
                @click="editusermodal = false"
              >
                <v-icon class="">fas fa-times</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form">
                <v-row justify="center" class="pt-4">
                  <v-col cols="12" md="12">
                    <v-text-field
                      append-icon="fas fa-user"
                      outlined
                      hide-details
                      v-model="edituser.username"
                      required
                      :rules="[(v) => !!v || 'Item is required']"
                      label="Username"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-select
                      v-model="edituser.rolle"
                      label="Rolle"
                      :items="['Admin', 'Leser', 'Editor']"
                      append-icon="fas fa-user-tag"
                      outlined
                      required
                      :rules="[(v) => !!v || 'Item is required']"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      append-icon="fas fa-key"
                      outlined
                      hide-details
                      v-model="edituser.newpassword"
                      label="Passwort"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-center">
              <div v-if="edituserbool">
                <v-btn class="primary" @click="save()">
                  <v-icon class="pr-2">fas fa-save</v-icon>Speichern
                </v-btn>
              </div>
              <div v-else>
                <v-btn class="primary" @click="save()">
                  <v-icon class="pr-2">fas fa-save</v-icon>Hinzufügen
                </v-btn>
              </div>
            </v-card-actions>
          </v-card></v-dialog
        >
      </v-card>
    </v-row>
    <v-dialog
      v-model="confirm"
      max-width="400px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>Löschen bestätigen</v-card-title>
        <v-card-actions
          ><v-btn class="success" @click="deleteuser()">Bestätigen</v-btn>
          <v-btn class="error" @click="confirm = false">Abrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    edituserbool: false,
    editusermodal: false,
    edituser: {},
    confirm: false,
    users: [],
    page: 1,
  }),
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        axios.post("/api/saveuser", this.edituser).then(
          (response) => {
      
            if (this.edituser.id)
              this.$root.$refs.snackbar.showmessage(
                "Erfolgreich Editiert.",
                "success"
              );
            else
              this.$root.$refs.snackbar.showmessage(
                "Erfolgreich angelegt.",
                "success"
              );
            axios.get("/api/getuser").then(
              (response) => {
          
                this.users = response.data;
                this.editusermodal = false;
              },
              (error) => {}
            );
          },
          (error) => {}
        );
      }
    },
    deleteuser() {
      axios.post("/api/deleteuser", this.edituser).then(
        (response) => {
          this.confirm = false;
          this.$root.$refs.snackbar.showmessage(
            "Erfolgreich gelöscht.",
            "success"
          );
          axios.get("/api/getuser").then(
            (response) => {
           
              this.users = response.data;

              this.editusermodal = false;
            },
            (error) => {}
          );
        },
        (error) => {}
      );
    },
  },
  activated: function () {
    axios.get("/api/getuser").then(
      (response) => {
      
        this.users = response.data;
      },
      (error) => {}
    );
  
  },
};
</script>
