require('./bootstrap');
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import VueRouter from 'vue-router'
import DatetimePicker from 'vuetify-datetime-picker'
Vue.use(VueRouter)
Vue.use(Vuetify)
Vue.use(DatetimePicker)
const opts = {}

export default new Vuetify(opts)


Vue.component('navapp', require('./components/navigation/Nav.vue').default);
Vue.component('snackbar', require('./components/mixins/Snackbar.vue').default);
Vue.component('logincomp', require('./components/pages/Login.vue').default);


import Home from './components/pages/Home.vue'
import Tickets from './components/pages/FacilityManager.vue'
import Bookings from './components/pages/Bookings.vue'
import ManageUser from './components/pages/admin/Manageuser.vue'
import MassImport from './components/pages/admin/Massimport.vue'
import Export from './components/pages/admin/Export.vue'

// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'



const routes = [
  { path: '/dashboard', component: Home},
  { path: '/tickets', component: Tickets},
  { path: '/bookings', component: Bookings},
  { path: '/admin/manageuser', component: ManageUser},
  { path: '/admin/massimport', component: MassImport},
  { path: '/admin/export', component: Export}
]
const router = new VueRouter({
  mode: 'history',
routes
})

export const globalStore = new Vue({
  data: {
    currentBuildingID: ""
  }
})
  new Vue({
    el: '#app',
    router,
     vuetify: new Vuetify({
      icons: {
       
        values: {
          checkboxOff: 'far fa-square',
          checkboxOn: 'fas fa-check-square',
          checkboxIndeterminate: 'fas fa-minus-circle',
          radioOff: 'far fa-circle',
          radioOn: 'fas fa-check-circle',
          clear: 'fa fa-ban',
          first: 'fas fa-arrow-circle-left',
          last: 'fas fa-arrow-circle-right',
          prev: 'fas fa-arrow-left',
          next: 'fas fa-arrow-right',
          dropdown: 'fas fa-caret-down'
        
        },
      },
      theme: {
        themes: {
          light: {
            primary: '#c5b187',
            secondary: '#b0bec5',
            accent: '#8c9eff',
            error: '#b71c1c',
          },
        },
      },
     }),
    data: {
     user: window.user,
     csfr: window.axios.defaults.headers.common['X-CSRF-TOKEN'],
  
    },
    created: function () {
   
    },
    computed: {

    },
    methods:{

    }
  })